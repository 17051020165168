html {
  width: 100%;
  overflow-x: hidden;
}

.sketchesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  background: radial-gradient(
    circle at center,
    #f39b9a,
    rgba(145, 152, 229, 0) 35%
  );
}

.more-title {
  color: #e8e6d4;
  font-size: 30px;
  font-weight: 600;
}

.btn {
  background-color: #f39b9a;
  border-radius: 10px;
  color: #e8e6d4;
  font-weight: 400;
  margin-top: 20px;
  text-decoration: none;
}
