html {
  width: 100%;
  overflow-x: hidden;
}

/* NAV */
.nav-link {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  text-decoration: none;
  margin: 30px 50px;
  font-size: 15px;
  padding: 0;
}

.nav-link:hover {
  color: #f39b9a;
  font-weight: 600;
}

/* GENERAL LAYOUT */
.creative-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: #e8e6d4;
  background: radial-gradient(at center right, #f39b9a, #e8e6d4);
  background-repeat: no-repeat;
  background-size: cover;
}

/* SECTION TITLE */
/* SECTION TITLE */
.section-title {
  width: 100%;
  margin-bottom: 5%;
  margin-top: 10%;
}

.section-title h2 {
  color: #f39b9a;
  font-size: 25px;
  text-align: left; /* Ensure left alignment */
  font-weight: 600;
}

.section-title p {
  color: #282626;
  font-size: 17px;
  text-align: left; /* Ensure left alignment */
  margin: 10px 0;
}

/* IMAGES */
.img-fluid {
  max-height: 300px;
  width: 100%;
  padding: 10px 0;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.image-container {
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}

.image-container img {
  max-height: 40vh;
  width: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.hover-description {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  width: 150px;
  padding: 5px 10px;
  border-radius: 6px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Motion Specific Styles */
.motion-container {
  transition: height 0.5s ease, width 0.5s ease;
}

.motion-element-focused {
  border: 2px solid #f39b9a;
}

/* Custom Modal Styles */
.custom-modal .modal-dialog {
  max-width: 45vw;
  width: 45vw;
  max-height: 80vh;
  height: 80vh;
}

.custom-modal .modal-content {
  padding: 20px;
}

.custom-modal .modal-body {
  text-align: left;
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
  text-align: left;
}
