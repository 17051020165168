html {
  width: 100%;
  overflow-x: hidden;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
}

.titleContact {
  margin: 0px 0px 10px 0px;
  font-size: 30px;
  font-weight: 600;
}

.subtitleContact  {
  font-size: 25px;
}

.svgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.svgContainer svg {
  width: 100px;
  margin: 70px 20px 0px 20px;
}
