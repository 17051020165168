html {
  width: 100%;
  overflow-x: hidden;
  text-decoration: none;
}

.projectContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 95vh;
  text-decoration: none;
  background: linear-gradient(180deg, #f39b9a 0%, rgba(243, 155, 154, 0) 100%);
}

.project {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: right;

  justify-content: space-between;
  height: 100%;
  padding: 15px;
  text-decoration: none;
}

.project:hover {
  background: linear-gradient(200deg, #f39b9a 0%, rgba(243, 155, 154, 0) 100%);
  border: 1px solid #e8e6d4;
}

.projectTitle,
.projectSubtitle,
.projectNumber {
  text-align: right;
  text-decoration: none;
}

.titleSubtitleContainer {
  margin: 0px 50px 0px 0px;
}

.projectTitle {
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  text-decoration: none;
}

.projectTitle h1 {
  text-decoration: none;
}

.projectSubtitle {
  color: #1e2124;
}

.projectNumber {
  font-size: 30px;
  font-weight: 800;
  margin: 0px 50px 0px 0px;
  color: #e8e6d4;
  text-decoration: none;
}

a.linkProjects {
  text-decoration: none;
}

.picProject {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}

.hoverText {
  /*TITLE*/
  position: absolute;
  bottom: 36%;
  left: 0%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 10%;
  text-align: center;
  box-sizing: border-box;

  display: block;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  color: #e8e6d4;
  font-size: 20px;
  font-weight: 600;
}

.hoverText2 {
  position: absolute;
  bottom: 20%;
  transform: translateX(-50%);
  width: 90%;
  padding: 0 10%;
  text-align: center;
  box-sizing: border-box;

  display: block;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  color: #e8e6d4;
  font-size: 15px;
  font-weight: 400;
}

.project:hover .hoverText,
.project:hover .hoverText2 {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .projectContainer {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    height: auto;
  }

  .project {
    height: auto;
    padding: 20px;
  }

  .picProject {
    width: 60%;
  }

  .hoverText {
    font-size: 20px;
  }

  .hoverText2 {
    font-size: 16px;
  }
}
