html {
  width: 100%;
  overflow-x: hidden;
}

/********* 
**** smartphones, portrait iPhone, portrait 480x320 phones (Android) 
/*********/
@media (min-width: 320px) {
}

/********* 
**** smartphones, Android phones, landscape iPhone 
/*********/
@media (min-width: 480px) {
}

/********* 
**** portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android)
/*********/
@media (min-width: 600px) {
}

/********* 
********* 
****  tablet, landscape iPad, lo-res laptops ands desktops
********* 
/*********/
@media (min-width: 801px) {
  .experiencesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;

    margin: 0 auto;
    max-width: 100%;
    width: 80%;
    height: 80vh;
  }

  /****
  Grid shenanigans
  *****/

  .experienceText,
  .skillsText {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  /*****
  Some styling
  *****/

  /** titles & subtitles **/

  .experienceTitle,
  .educationTitle,
  .skillsTitle {
    font-size: 35px;
    font-weight: 500;
    font-family: Switzer, system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
    position: absolute;
  }

  .experienceTitle {
    top: 110%;
  }

  .educationTitle,
  .skillsTitle {
    top: 152%;
  }

  .titleExperience1,
  .titleExperience2,
  .titleExperience3,
  .titleEducation {
    position: absolute;
    color: #1e2124;
    font-size: 30px;
    font-weight: 400;
  }

  .titleExperience1,
  .titleExperience2,
  .titleExperience3 {
    top: 120%;
  }

  .titleEducation {
    top: 162%;
  }

  /** text **/

  .textExperience1,
  .textExperience2,
  .textExperience3,
  .textEducation2,
  .skillsText {
    position: absolute;
    color: #1e2124;
    font-size: 20px;
  }

  .textExperience1,
  .textExperience2,
  .textExperience3 {
    top: 125%;
  }

  .textEducation2 {
    top: 167%;
  }

  .skillsText {
    top: 162%;
  }

  .skill1 p,
  .skill2 p {
    margin-bottom: 10px;
  }

  .skill2 {
    margin-left: 70px;
  }
}

/********* 
********* 
****  common pc
********* 
/*********/
@media (min-width: 1920px) {
  .experiencesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;

    margin: 0 auto;
    max-width: 100%;
    width: 85%;
    height: 80vh;
    right: 10%;
  }

  /****
  Grid shenanigans
  *****/

  .experienceText,
  .skillsText {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  /*****
  Some styling
  *****/

  /** titles & subtitles **/

  .experienceTitle,
  .educationTitle,
  .skillsTitle {
    font-size: 45px;
    font-weight: 500;
    font-family: Switzer, system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
    position: absolute;
  }

  .experienceTitle {
    top: 110%;
  }

  .educationTitle,
  .skillsTitle {
    top: 152%;
  }

  .titleExperience1,
  .titleExperience2,
  .titleExperience3,
  .titleEducation {
    position: absolute;
    color: #1e2124;
    font-size: 35px;
    font-weight: 400;
  }

  .titleExperience1,
  .titleExperience2,
  .titleExperience3 {
    top: 120%;
  }

  .titleEducation {
    top: 162%;
  }

  /** text **/

  .textExperience1,
  .textExperience2,
  .textExperience3,
  .textEducation2,
  .skillsText {
    position: absolute;
    color: #1e2124;
    font-size: 25px;
  }

  .textExperience1,
  .textExperience2,
  .textExperience3 {
    top: 125%;
  }

  .textEducation2 {
    top: 167%;
  }

  .skillsText {
    top: 162%;
  }

  .skill1 p,
  .skill2 p {
    margin-bottom: 10px;
  }

  .skill2 {
    margin-left: 20px;
  }
}

/********* 
********* 
****  big landscape tablets, laptops, and desktops
********* 
/*********/
@media (min-width: 1025px) {
  .experiencesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;

    margin: 0 auto;
    max-width: 100%;
    width: 85%;
    height: 80vh;
    right: 10%;
  }

  /****
  Grid shenanigans
  *****/

  .experienceText,
  .skillsText {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  /*****
  Some styling
  *****/

  /** titles & subtitles **/

  .experienceTitle,
  .educationTitle,
  .skillsTitle {
    font-size: 35px;
    font-weight: 500;
    font-family: Switzer, system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
    position: absolute;
  }

  .experienceTitle {
    top: 110%;
  }

  .educationTitle,
  .skillsTitle {
    top: 152%;
  }

  .titleExperience1,
  .titleExperience2,
  .titleExperience3,
  .titleEducation {
    position: absolute;
    color: #1e2124;
    font-size: 25px;
    font-weight: 400;
  }

  .titleExperience1,
  .titleExperience2,
  .titleExperience3 {
    top: 120%;
  }

  .titleEducation {
    top: 162%;
  }

  /** text **/

  .textExperience1,
  .textExperience2,
  .textExperience3,
  .textEducation2,
  .skillsText {
    position: absolute;
    color: #1e2124;
    font-size: 18px;
  }

  .textExperience1,
  .textExperience2,
  .textExperience3 {
    top: 125%;
  }

  .textEducation2 {
    top: 167%;
  }

  .skillsText {
    top: 162%;
  }

  .skill1 p,
  .skill2 p {
    margin-bottom: 10px;
  }

  .skill2 {
    margin-left: 20px;
  }
}
