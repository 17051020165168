@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Switzer, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: #e8e6d4;
  background: radial-gradient(at center right, #f39b9a, #e8e6d4);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.App {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

h1 {
  color: #f39b9a;
}

p {
  font-family: #1e2124;
}

.navigation-container {
  position: fixed;
}
